import { Component, Ref } from 'react'



import { Ticker, TickerProxy } from '../../actions/types/portfolioDesigner';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { getTickerProxy, removeTickerProxy, updateTickerProxy, updateTickerProxyMapping } from '../../actions/portfolioDesigner';
import TickerInput from './TickerInput';
import { connect } from 'react-redux';
import { addError } from '../../actions/notifications';
import { Box, Switch, SxProps } from '@mui/material';
import { Theme } from '../../theme/theme';

interface ComponentProps {
    ticker: Ticker
    onTickerProxyUpdated?: (ticker?: Ticker ) => void
    onEnableToggled?: (enabled: boolean) => void
    updateTickerProxyMapping: typeof updateTickerProxyMapping
    proxy?: TickerProxy
    containerSx?: SxProps<Theme>
    inputContainerSx?: SxProps<Theme>
    hideEnableToggle?: boolean
}

interface TickerProxyInputProps extends ComponentProps {
    addError: typeof addError
}

interface TickerProxyInputState { }

class TickerProxyInput extends Component<TickerProxyInputProps & {forwardRef?: React.Ref<any>}, TickerProxyInputState> {
    state: TickerProxyInputState = { }

    componentDidMount() {
        if (this.props.ticker) {
            this.onLoadTickerProxy(this.props.ticker)
        }
    }

    componentDidUpdate(prevProps: Readonly<TickerProxyInputProps & { forwardRef?: Ref<any> | undefined; }>, prevState: Readonly<TickerProxyInputState>, snapshot?: any): void {
        if (this.props.ticker?.id !== prevProps.ticker?.id) {
            this.setState({ tickerProxy: undefined })
            this.onLoadTickerProxy(this.props.ticker)
        }

        if (this.props.proxy?.ticker !== prevProps.proxy?.ticker) {
            this.setState({ tickerProxy: this.props.proxy?.ticker, enabled: this.props.proxy?.enabled || false })
        }
    }

    onLoadTickerProxy = async(ticker?: Ticker) => {
        if (!ticker) {
            return;
        }
        try {
            let proxy: TickerProxy | undefined = this.props.proxy
            if (!proxy) {
                proxy = await getTickerProxy(ticker)
                this.props.updateTickerProxyMapping(ticker, proxy)
            }
        } catch(e) {
            this.props.addError('Historic Backfill', 'Error loading historic backfill ticker')
        }
    }

    onSelectedTickerProxy = async(ticker?: Ticker ) => {
        if (!this.props.ticker) {
            return;
        }
        const { ticker: tickerProxy, enabled } = this.props.proxy || {}
        try {
            if (ticker) {
                await updateTickerProxy(this.props.ticker, ticker, tickerProxy ? true : enabled || false)
            } else {
                await removeTickerProxy(this.props.ticker)
            }

            if (this.props.onTickerProxyUpdated) {
                this.props.onTickerProxyUpdated(ticker)
            }
            this.props.updateTickerProxyMapping(this.props.ticker, { ticker, enabled: enabled || false })
        } catch(e) {
            this.props.addError('Historic Backfill', 'Error updating historic backfill ticker')
        }
    }

    onEnableToggled = async () => {
        const { ticker: tickerProxy, enabled } = this.props.proxy || {}
        if (!tickerProxy) {
            return;
        }

        if (this.props.ticker && tickerProxy) {
            if (this.props.onEnableToggled) {
                this.props.onEnableToggled(!enabled)
            }

            await updateTickerProxy(this.props.ticker, tickerProxy, !enabled)
        }
        this.props.updateTickerProxyMapping(this.props.ticker, { ticker: tickerProxy, enabled: !enabled })
    }

    render() {
        const { inputContainerSx = {}, hideEnableToggle, proxy } = this.props

        return (
            <Box sx={{ display: 'flex', flex: 1 }}>
                {!hideEnableToggle && 
                        <Switch 
                            checked={proxy?.enabled || false} 
                            disabled={proxy?.ticker ? false : true}
                            onChange={this.onEnableToggled}
                        />
                    }
                <TickerInput
                    containerSx={inputContainerSx}
                    sx={{
                        '& input': {
                            font: FONT_STYLES_BY_TYPE.text_tiny,
                        },
                    }}
                    placeholder='Historic Backfill'
                    value={proxy?.ticker}
                    onTickerSelected={this.onSelectedTickerProxy}
                />
            </Box>
        )
    }
}

const mapStateToProps = (state: any, { ticker }: any) => {
    const { portfolioDesigner } = state
    const { proxyMapping } = portfolioDesigner

    return {
        proxy: proxyMapping[ticker?.id],
    }
}
export default connect<any, any, ComponentProps, any>(mapStateToProps, { addError, updateTickerProxyMapping })(TickerProxyInput as any);
