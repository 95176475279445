import { Box } from '@mui/material'
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { BaseProps } from '../types'
import Text from './styled/Text';

export interface ModalProps extends BaseProps {
    onCancel: () => void
    visible: boolean
    title: string
    children?: React.ReactNode
    footerChildren?: React.ReactNode
    hideHeader?: boolean
    hideCloseButton?: boolean
    size?: 'sm' | 'lg' | 'xl'
}

export default class extends Component<ModalProps> {

    render() {
        const { sx = {}, size = 'lg', visible, title, children, hideHeader = false, hideCloseButton = false, footerChildren } = this.props
        if(!visible) {
            return <></>
        }

        return (
            <Box sx={{ 
                position: 'fixed',
                zIndex: 1000,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                '& .modal-body': {
                    maxHeight: 'calc(100vh - 275px)',
                    overflowY: 'auto',
                    ...(sx['& .modal-body'] || {} )
                },
                ...sx
            }}>
                <Modal.Dialog size={size}>
                    {!hideHeader && 
                        <Modal.Header closeButton={!hideCloseButton} onHide={this.props.onCancel}>
                            <Text type='h4'>{title}</Text>
                        </Modal.Header>
                    }
                    <Modal.Body>
                        {children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                            {footerChildren}
                        </Box>
                    </Modal.Footer>
                </Modal.Dialog>
            </Box>
        )
    }
}