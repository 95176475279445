import { Box } from "@mui/material"
import { Component } from "react"
import { connect } from 'react-redux'
import { withClientParameter } from "../../common/withClientParameter"
import { runSimplifyAlternativesUpdate } from "../../../actions/portfolioDesigner"
import { TickerAllocation, DateLogs, AlternativeTables } from "../../../actions/types/portfolioDesigner"

import { withWindowDimensions, WithWindowDimensionsProps } from "../../common/withWindowDimensions"
import { ClipLoader } from "react-spinners"
import { withAuth, withAuthProps } from "../../../common/hooks/withAuth"
import { ReduxState } from "../../../reducers"
import { ResultDateLogs } from "../ResultDateLogs"
import ExtendedCommonTable from "../../../components/common/ExtendedCommonTable"

export interface PortfolioDesignerProps extends WithWindowDimensionsProps, withAuthProps {
        alternativesLoading: boolean;
        alternativesTables: AlternativeTables[];
        alternativesTotalReturnStatistics: {
            [key: string]: {
                annualized_return: number,
                annualized_volatility: number,
                annualized_return_over_volatility: number,
                expense_ratio: number
            }
        };
        alternativesDateLogs: DateLogs
        portfolioPortfolio: TickerAllocation[]
        runSimplifyAlternativesUpdate: () => void
}
export interface PortfolioDesignerState {
}
class SimplifyAlternatives extends Component<PortfolioDesignerProps, PortfolioDesignerState> {
    resultsRef?: any

    state = {
        runningHistoricalAnalysis: false,
        runningReportDownload: false,
        runningExcelDownload: false,
        showReportOptions: false,
    }

    componentDidMount(): void {
        this.props.runSimplifyAlternativesUpdate()
    }

    componentDidUpdate(prevProps: Readonly<PortfolioDesignerProps>, prevState: Readonly<PortfolioDesignerState>, snapshot?: any): void {
        if (this.props.portfolioPortfolio !== prevProps.portfolioPortfolio) {
            const prevTickers = prevProps.portfolioPortfolio.map((item) => item.ticker)
            const currentTickers = this.props.portfolioPortfolio.map((item) => item.ticker)
            if (prevTickers.join(',') !== currentTickers.join(',')) {
                this.props.runSimplifyAlternativesUpdate()
            }
        }
    }

    render() {
        const { alternativesLoading, alternativesTables, alternativesDateLogs } = this.props

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '50rem', width: '100%', justifyContent: 'center' }}>
                {alternativesLoading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <ClipLoader color={'#123abc'} loading={true} />
                    </Box>                    
                )}
                {!alternativesLoading && (
                    <Box>
                        {alternativesTables?.map((table, index) => {
                            return (
                                <>
                                    <ExtendedCommonTable
                                        title={`${table.ticker} - ${table.alternatives.join(', ')}`}
                                        key={table.ticker}
                                        tableSx={{
                                            ml: '7.5rem',
                                            maxWidth: '89rem',
                                            width: '100%', 
                                            '& th': {
                                                textAlign: 'center',
                                            },
                                            '& td': {
                                                textAlign: 'center',
                                            }
                                        }}
                                        headerColumns={table.columns}
                                        rows={table.data}
                                        sx={{ 
                                            '& th': {
                                                textAlign: 'center',
                                            },
                                            '& td': {
                                                textAlign: 'center',
                                            },
                                        }}
                                        footerAttributionSx={{ maxWidth: '100rem' }}
                                        footerOnlyInExport={true}
                                    />
                                    <ResultDateLogs
                                        filter={[table.ticker, ...table.alternatives]}
                                        logs={alternativesDateLogs}
                                        sx={{
                                            mb: '1rem',
                                        }}
                                    />
                                </>
                            )
                        })}
                    </Box>
                )}
            </Box>
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { alternativesLoading, alternativesDateLogs, alternativesTables, portfolioPortfolio } = portfolioDesigner

    return { 
        alternativesLoading,
        alternativesDateLogs,
        alternativesTables,
        portfolioPortfolio,
    }
}

export default withAuth(withWindowDimensions(withClientParameter(connect(mapStateToProps, { runSimplifyAlternativesUpdate })(SimplifyAlternatives as  any))))
