import { Box } from "@mui/material"
import { Component } from "react"
import { connect } from 'react-redux'
import Text from "../common/Text"
import { withClientParameter } from "../common/withClientParameter"
import { SelectOption } from "../../common/types"
import { updatePortfolioForType, updatePortfoliHistoryForType, updateParameter, loadModelOptions, loadConfigurationOptions, runAnalyzer } from "../../actions/portfolioDesigner"
import { TickerAllocation, PortfolioDesignerConfigurationType, ActionStatus, ResultsTableData, TYPE_TABLE_MAPPING, ReportResourceItem, PortfolioHistory } from "../../actions/types/portfolioDesigner"
import { v4 as uuid } from 'uuid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faWarning } from '@fortawesome/free-solid-svg-icons'
import { withWindowDimensions, WithWindowDimensionsProps } from "../common/withWindowDimensions"
import PortfolioDesignerSection from "./PortfolioDesignerSection"
import { ClipLoader } from "react-spinners"
import { withAuth, withAuthProps } from "../../common/hooks/withAuth"
import { ReduxState } from "../../reducers"
import PortfolioDesignerTabs from "./Tabs"

export interface PortfolioDesignerProps extends WithWindowDimensionsProps, withAuthProps {
    benchmarkPortfolio: TickerAllocation[]
    portfolioPortfolio: TickerAllocation[]
    benchmarkRebalancingFrequency: SelectOption
    portfolioRebalancingFrequency: SelectOption
    snapshot: ResultsTableData
    loadModelOptions: typeof loadModelOptions,
    loadConfigurationOptions: typeof loadConfigurationOptions,
    updatePortfolioForType: (type: PortfolioDesignerConfigurationType, portfolio: TickerAllocation[], changedItem?: TickerAllocation, itemUpdateType?: 'weight' | 'ticker') => any
    updatePortfoliHistoryForType: (type: PortfolioDesignerConfigurationType, history: PortfolioHistory[]) => any
    updateParameter: (payload: any) => any
}
export interface PortfolioDesignerState {
}
class PortfolioDesigner extends Component<PortfolioDesignerProps, PortfolioDesignerState> {
    mainView?: any
    resultsRef?: any

    componentDidMount(): void {
        this.props.loadModelOptions();
        this.props.loadConfigurationOptions();
    }

    onTransferToPortfolio = () => {
        const { benchmarkPortfolio: portfolioForTransfer } = this.props

        const transferPortfolio = portfolioForTransfer.map((item) => {
            return { ...item, id: uuid() }
        })

        this.props.updatePortfolioForType('portfolio', transferPortfolio)
    }

    onTransferToBenchmark = () => {
        const { portfolioPortfolio: portfolioForTransfer } = this.props

        const transferPortfolio = portfolioForTransfer.map((item) => {
            return { ...item, id: uuid() }
        })

        this.props.updatePortfolioForType('benchmark', transferPortfolio)
    }

    updatePortfolioForType = (type: PortfolioDesignerConfigurationType, portfolio: TickerAllocation[], changedItem?: TickerAllocation, itemUpdateType?: 'weight' | 'ticker') => {
        this.props.updatePortfolioForType(type, portfolio, changedItem, itemUpdateType)
    }

    render() {
        const { dimensions } = this.props

        return (
            <Box ref={view => this.mainView = view } sx={{ display: 'flex', flexDirection: 'column', minHeight: '50rem', width: dimensions.width - 100 }}>
               <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        lg: 'row', 
                    },
                    flex: 1,
                    width: '100%',
               }}>
                    <PortfolioDesignerSection type={'benchmark'} updatePortfolioForType={this.updatePortfolioForType}/>
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '2.5rem',
                        marginLeft: '2.5rem',
                    }}>
                        <Box component={'button'} sx={{ marginBottom: '.5rem', all: 'unset', cursor: 'pointer', padding: '.25rem' }}>
                            <FontAwesomeIcon size={'2x'} icon={faArrowRight} onClick={this.onTransferToPortfolio}/>
                        </Box>
                        <Box component={'button'} sx={{ marginTop: '.5rem', all: 'unset', cursor: 'pointer', padding: '.25rem' }}>
                            <FontAwesomeIcon size={'2x'} icon={faArrowLeft} onClick={this.onTransferToBenchmark}/>
                        </Box>
                    </Box>
                    <PortfolioDesignerSection type={'portfolio'} updatePortfolioForType={this.updatePortfolioForType} updatePortfoliHistoryForType={this.props.updatePortfoliHistoryForType} />
               </Box>
               <Box 
                    sx={{ 
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                md: 'row', 
                            },
                            marginBottom: '1rem',
                            width: '100%'
                    }}
                >
                    <PortfolioDesignerTabs />
               </Box>
            </Box>
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { 
        benchmarkPortfolio,
        portfolioPortfolio,
        benchmarkRebalancingFrequency,
        portfolioRebalancingFrequency,
        snapshot,
    } = portfolioDesigner

            

    return { 
        benchmarkPortfolio,
        portfolioPortfolio,
        benchmarkRebalancingFrequency,
        portfolioRebalancingFrequency,
        snapshot,
    };
}

export default withAuth(withWindowDimensions(withClientParameter(connect(mapStateToProps, { updatePortfolioForType, updatePortfoliHistoryForType, updateParameter, loadModelOptions, loadConfigurationOptions })(PortfolioDesigner as  any))))
