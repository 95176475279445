import { Box, TextField, TextFieldProps } from '@mui/material'
import React, { Component } from 'react'
import { BaseProps } from '../types'

interface InputState {
    inputHasFocus?: boolean
    value: string | number
}

interface InputProps extends React.InputHTMLAttributes<any>, BaseProps {
    value?: number
    accuracy?: number
    decimalPlaces?: number
    isPercentage?: boolean
    extendedInput?: boolean
    useTextField?: boolean
    textFieldProps?: TextFieldProps
    onValueChange?: (value: any, focusLost?: boolean) => void
    onChange?: (value: any, focusLost?: boolean) => void
    onBlur?: (value?: any) => void
    onFocus?: (value?: any) => void
    onEnterPressed?: () => void
    onlyNotifyOnBlur?: boolean
}

export default class extends Component <InputProps, InputState> {
    inputRef: any
    constructor(props) {
        super(props)
        const { value, decimalPlaces = 0, isPercentage } = props
        this.state = {
            value: this.formatDisplayValue({ value, isPercentage, decimalPlaces }),
            inputHasFocus: false
        }
    }

    formatDisplayValue = ({ value, isPercentage, decimalPlaces }) => {
        const displayValue = value == null || value == undefined || isNaN(value) ? this.props.defaultValue || '' : value
        try {
            if(isPercentage) {
                return (displayValue * 100).toFixed(decimalPlaces)
            } else if(decimalPlaces) {
                return displayValue.toFixed(decimalPlaces)
            }
    
            return displayValue.toLocaleString('en-US')
        } catch(e: any) {
        }

        return displayValue
    }

    componentDidUpdate(prevProps) {
        const { inputHasFocus } = this.state
        const { value, decimalPlaces = 0, isPercentage } = this.props
        const { value: prevValue } = prevProps

        if(value == prevValue || inputHasFocus) {
            return
        }

        this.setState({ value: this.formatDisplayValue({ value, isPercentage, decimalPlaces }) })
    }

    onChange = (e) => {
        let value = e.target.value
        const valueTest = parseFloat(value.replace(/,/g, '').replace(/[^0-9.-]/g,''), 10)
        
        if((value && value.length > 0 && value !== '-') && (value && isNaN(valueTest))) {
            return
        }
        
        if (this.props.min && value < this.props.min) {
            value = this.props.value
        }
        if (this.props.max && value > this.props.max) {
            value = this.props.value
        }
        

        this.setState({ value })

        if(this.props.onChange && !this.props.onlyNotifyOnBlur) {
            this.props.onChange(this.props.isPercentage ? value / 100 : value)
        }
    }

    onKeyDown = (e: any) => {
        if(e.key === 'Enter' && this.props.onEnterPressed) {
            this.props.onEnterPressed()
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(e)
        }
    }

    dispatchEvent = (...args: any) => {
        this.inputRef.dispatchEvent(...args)
    }

    focus = () => {
        this.inputRef.focus()
    }

    blur = () => {
        this.inputRef.blur()
    }

    onBlur = (e) => {
        const { decimalPlaces = 0, isPercentage } = this.props

        const parseFunction = decimalPlaces > 0 ? parseFloat : parseInt
        let value = e.target.value
        if(value != '') {
            if(value == '-') {
                value = '0';
            }

            value = parseFunction(value.replace(/,/g, '').replace(/[^0-9.-]/g,''), 10)
            if (isNaN(value)) {
                return
            }
        } else {
            value = this.props.defaultValue || ''
        }
    
        if (value && this.props.min && value < this.props.min) {
            value = this.props.value
        }
        if (value && this.props.max && value > this.props.max) {
            value = this.props.value
        }
        value = isPercentage ? value / 100 : value

        this.setState({ value: this.formatDisplayValue({ value, isPercentage, decimalPlaces }) })
        if(this.props.onChange) {
            this.props.onChange(value, true)
        }
        if (this.props.onValueChange) {
            this.props.onValueChange(value, true)
        }

        this.setState({ inputHasFocus: false })

        if(this.props.onBlur) {
            this.props.onBlur(e)
        }
    }

    onFocus = (e) => {
        this.setState({ inputHasFocus: true })
        if(this.props.onFocus) {
            this.props.onFocus(e)
        }
    }

    render() {
        const { onEnterPressed, useTextField, textFieldProps, extendedInput, sx = { }, decimalPlaces, isPercentage, ...restProps } = this.props
        const { value } = this.state

        if (useTextField) {
            return (
                    <TextField
                        {...(textFieldProps || {})}
                        ref={view => this.inputRef = view}
                        inputProps={{ 
                                sx: {
                                    width: extendedInput ? '15rem' : '6.5rem',
                                    ...sx,
                                },
                                ...restProps,
                                value,
                                onBlur: this.onBlur,
                                onChange: this.onChange,
                                onFocus: this.onFocus,
                                onKeyDown: this.onKeyDown,
                        }}
                    />
            )
        }

        return (
            <Box
                sx={{
                    '& input': {
                        width: extendedInput ? '15rem' : '6.5rem',
                        ...sx,
                    }
                }}>
                <input
                    {...restProps}
                    ref={view => this.inputRef = view}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onKeyDown={this.onKeyDown}
                    value={value}
                />
            </Box>
        )
    }
}