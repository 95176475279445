import { Box, TextField, Typography } from "@mui/material"
import { Component, Ref } from "react"
import { connect } from 'react-redux'
import { Select } from "../common/styled/Select"
import Text from "../common/Text"
import Button from "../common/Button"
import { SelectOption } from "../../common/types"
import { deleteConfiguration, saveConfiguration, updateConfiguration, expandTickerAllocation as expandTickerAllocation,
         updateRebalancingFrequencyForType, updateAdvisoryFeeForType, updateConfigsForType, updateModelsForType, getNewTickerListItem, deleteModel, saveModel,
         flattenedPortfolioHistory,
         expandPortfolioHistory} from "../../actions/portfolioDesigner"
import { TickerAllocation, PortfolioDesignerConfiguration, TickerAllocationFlat, PortfolioDesignerConfigurationType, REBALANCING_FREQUENCY, DEFAULT_OPTION, TYPE_LABEL, SnapshotItems, MetricDataMapped, PortfolioHistoryFlattened, PortfolioHistory, Ticker } from "../../actions/types/portfolioDesigner"
import { addError, addSuccessNotification } from "../../actions/notifications"
import PortfolioList from "./PortfolioList"
import ConfirmDeleteSetModal from '../CustomCMA/ConfirmDeleteSetModal'
import SaveAsModal from '../CustomCMA/SaveAsModal'

import ConfirmModal from "../common/ConfirmModal"
import { FileUpload } from '../common/FileUpload'
import { csvFileToJson } from "../../common/utils"
import TooltipContainer from "../common/styled/TooltipContainer"
import { ReduxState } from "../../reducers"
import { FONT_STYLES_BY_TYPE } from "../common/styled/Text"
import PortfolioHistoryModal from "./PortfolioHistoryModal"

export interface PortfolioDesignerProps {
    addError: (title: string, message: string) => any,
    addSuccessNotification: (notification: { title?: string, message: string }) => any,
    updatePortfolioForType: (type: PortfolioDesignerConfigurationType, portfolio: TickerAllocation[], changedItem?: TickerAllocation, itemUpdateType?: 'weight' | 'ticker') => any
    updatePortfoliHistoryForType: (type: PortfolioDesignerConfigurationType, history: PortfolioHistory[]) => any
    updateRebalancingFrequencyForType: (type: PortfolioDesignerConfigurationType, rebalancingFrequency: SelectOption) => any
    updateAdvisoryFeeForType: (type: PortfolioDesignerConfigurationType, advisoryFee: number) => any
    updateConfigsForType: (type: PortfolioDesignerConfigurationType, configs: SelectOption[]) => any
    updateModelsForType: (type: PortfolioDesignerConfigurationType, models: SelectOption[]) => any
    type: PortfolioDesignerConfigurationType
    portfolio: TickerAllocation[]
    portfolioHistory: PortfolioHistory[];
    configs: SelectOption[]
    models: SelectOption[]
    rebalancingFrequency: SelectOption
    snapshots: SnapshotItems
    snapshotLoading: boolean
    snapshotTargetItem?: TickerAllocation
    snapshotTargetItemUpdateType?: 'weight' | 'ticker'
    metrics: MetricDataMapped
    advisoryFee: number
}
export interface PortfolioDesignerState {
    portfolioWeightTotal: number
    showConfirmDeleteModal: boolean
    showSaveAsModal: boolean
    showPortfolioUpload: boolean
    showLoadingPortfolio: boolean
    selectedConfig?: SelectOption | null
    selectedModel?: SelectOption | null
    selectedFile?: File
    showConfirmDeleteModelModal: boolean
    advisoryFee?: string | null
    showPortfolioHistoryModal: boolean
    loadingHistory: boolean
    flattenedHistory: PortfolioHistoryFlattened[]
    historyDates: string[]
}
class PortfolioDesigner extends Component<PortfolioDesignerProps, PortfolioDesignerState> {
    portfolioList?: PortfolioList
    uploadRef?: FileUpload

    constructor(props: PortfolioDesignerProps) {
        super(props)

        const portfolioWeightTotal = this.props.portfolio.reduce((accum, item) => {
            return item.weight ? item.weight + accum : accum
        }, 0)

        this.state = {
            portfolioWeightTotal,
            showConfirmDeleteModal: false,
            showSaveAsModal: false,
            showPortfolioUpload: false,
            showLoadingPortfolio: false,
            showConfirmDeleteModelModal: false,
            advisoryFee: '0.0',
            showPortfolioHistoryModal: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<PortfolioDesignerProps>, prevState: Readonly<PortfolioDesignerState>, snapshot?: any): void {
        if(prevProps.portfolio !== this.props.portfolio) {
            const portfolioWeightTotal = this.props.portfolio.reduce((accum, item) => {
                return item.weight ? item.weight + accum : accum
            }, 0)
            this.setState({ portfolioWeightTotal })
        }
        if (prevProps.advisoryFee !== this.props.advisoryFee) {
            const advisoryFee = Number(this.props.advisoryFee);
            this.setState({ advisoryFee: isNaN(advisoryFee) ? '0.0' : advisoryFee.toFixed(2) });
        }
    }

    onModelSelected = (selectedModel: SelectOption) => {
        this.setState({ selectedModel, selectedConfig: null })
        if (selectedModel) {
            const item = selectedModel.item
            this.onLoadConfiguration({...selectedModel.item, type: this.props.type } as PortfolioDesignerConfiguration)
        }
    }

    onConfigurationSelected = (selectedConfig: SelectOption) => {
        this.setState({ selectedConfig, selectedModel: null })
        if (selectedConfig) {
            this.onLoadConfiguration(selectedConfig.item as PortfolioDesignerConfiguration)
        }
    }

    onLoadConfiguration = async(config: PortfolioDesignerConfiguration) => {
        const { type, portfolio, history, rebalancingFrequency, advisoryFee } = config

        const frequency = REBALANCING_FREQUENCY.find(({ value }) => rebalancingFrequency === value) || DEFAULT_OPTION
        
        let portfolioToLoad: TickerAllocation[] = await expandTickerAllocation(portfolio as TickerAllocationFlat[])
        portfolioToLoad = [...portfolioToLoad, getNewTickerListItem()]
        this.props.updatePortfolioForType(type, portfolioToLoad);
        if (this.props.updatePortfoliHistoryForType) {
            this.props.updatePortfoliHistoryForType(type, history || []);
        }
        this.props.updateRebalancingFrequencyForType(type, frequency);
        this.props.updateAdvisoryFeeForType(type, advisoryFee)
    }

    onClearItems = () => {
        const { type } = this.props
        this.setState({ selectedModel: null, selectedConfig: null })
        this.props.updatePortfolioForType(type, [getNewTickerListItem()])
    }

    getConfigurationForSave = (name: string): PortfolioDesignerConfiguration => {
        const { type, portfolio, portfolioHistory, rebalancingFrequency, advisoryFee } = this.props

        return {
            id: 0,
            type,
            name,
            rebalancingFrequency: rebalancingFrequency.value as string,
            portfolio,
            history: portfolioHistory,
            advisoryFee,
        };
    }

    onSaveConfigration = async(name: string) => {
        const configForSave = this.getConfigurationForSave(name)
        const config = await saveConfiguration(configForSave, true);

        if (!config) {
            return this.props.addError('Save Error', 'An error occurred saving portfolio, please try again.' )
        }
        this.props.addSuccessNotification({ message: 'Succesfully saved portfolio' })

        const { type, configs } = this.props

        const configOption: SelectOption = { label: config.name, value: config.id, item: config }
        const update = [...configs, configOption]

        this.props.updateConfigsForType(type, update);
        this.setState({ selectedConfig: configOption, selectedModel: null })
    }
    onUpdateConfigurationForName = async(id: number, name: string) => {
        const configForSave = this.getConfigurationForSave(name)

        this.onUpdateConfiguration(id, configForSave)
    }

    onUpdateConfiguration = async(id: number, configForSave: PortfolioDesignerConfiguration) => {
        let config: PortfolioDesignerConfiguration | undefined;
        try {
            config = await updateConfiguration({ ...configForSave, id });
        } catch (error) {}

        if (!config) {
            return this.props.addError('Save Error', 'An error occurred saving portfolio, please try again.' )
        }
        this.props.addSuccessNotification({ message: 'Succesfully saved portfolio' })

        const configOption = { label: config.name, value: config.id, item: config }

        if (this.state.selectedConfig) {
            const { type, configs } = this.props
            this.setState({ selectedConfig: configOption })
            const updatedConfigs = configs.map((item) => {
                if (item.value === id) {
                    return configOption
                }
    
                return { ...item };
            })
            this.props.updateConfigsForType(type, updatedConfigs);
        } 
        
        if (this.state.selectedModel) {
            const { type, models } = this.props
            this.setState({ selectedModel: configOption })
            const updatedModels = models.map((item) => {
                if (item.value === id) {
                    return configOption
                }
    
                return { ...item };
            })
            this.props.updateModelsForType(type, updatedModels);
        }
        this.onLoadConfiguration(configOption.item as PortfolioDesignerConfiguration)
    }
    

    onAddToPortfolio = () => {
        const { type, portfolio } = this.props
        const updated = [...portfolio, getNewTickerListItem()]
        this.props.updatePortfolioForType(type, updated)
        this.portfolioList?.scrollToBottom()
    }

    onPortfolioItemChanged = (item: TickerAllocation, index: number, updateType: 'weight' | 'ticker') => {
        const { type, portfolio = []} = this.props;
        const unchangedItem = portfolio[index]
        const forceUpdate = (item?.ticker as any)?.forceUpdate
        if (!forceUpdate && ((unchangedItem.ticker?.id === item.ticker?.id) && (unchangedItem.weight === item.weight))) {
            return
        }

        let updated = portfolio.map((mapItem, filterIndex) => {
            if (filterIndex === index) {
                return item
            }

            return mapItem
        })

        if (updated[updated.length - 1].ticker) {
            updated = [...updated, getNewTickerListItem()]
        }

        this.props.updatePortfolioForType(type, updated, item, updateType)
    }

    onPortfolioItemRemoved = (_: TickerAllocation, index: number) => {
        const { type, portfolio = [] } = this.props;
        let update = portfolio.filter((_, filterIndex) => filterIndex !== index)
        
        if (update.length === 0 || update[update.length - 1].ticker) {
            update = [...update, getNewTickerListItem()]
        }

    
        this.props.updatePortfolioForType(type, update)
    }

    onBalancingFrequencyChanged = (value: SelectOption) => {
        const { type } = this.props
        this.props.updateRebalancingFrequencyForType(type, value)
    }

    onSaveAsConfirmed = async (name: string) => {
        this.setState({ showSaveAsModal: false })
        const { configs, type } = this.props
        const existingConfig = configs.find((item) => item.item.type === type && item.label === name)
        if (existingConfig) {
            await this.onUpdateConfigurationForName(existingConfig.value as number, name)

            return
        }

        await this.onSaveConfigration(name)
    }

    onDeleteConfirmed = async() => {
        const { selectedConfig } = this.state;
        if (!selectedConfig) {
            return
        }

        const success = await deleteConfiguration(selectedConfig.item)
        if (!success) {
            this.props.addError('Delete', 'An error occured, please try again')

            return
        }
        
        this.props.addSuccessNotification({ message: 'Succesfully deleted saved portfolio' })
        const { type, configs } = this.props
        const configsUpdated = configs.filter(({ value: configId }) => configId !== selectedConfig.value)
        
        this.props.updateConfigsForType(type, configsUpdated);
        this.setState({ selectedConfig: null })
    }

    onDelete = () => {
        this.setState({ showConfirmDeleteModal: true })
    }

    onSave = () => {
        this.setState({ showSaveAsModal: true })
    }


    onDeleteModel = () => {
        this.setState({ showConfirmDeleteModelModal: true })
    }

    onDeleteModelConfirmed = async() => {
        const { type, models } = this.props
        const { selectedModel } = this.state;
        if (!selectedModel) {
            return
        }

        const success = await deleteModel(selectedModel.item)
        if (!success) {
            this.props.addError('Delete', 'An error occured, please try again')

            return
        }

        this.props.addSuccessNotification({ message: 'Succesfully deleted saved model' })
        const modelsUpdated = models.filter(({ value: configId }) => configId !== selectedModel.value)

        this.props.updateModelsForType(type, modelsUpdated)
        this.setState({ selectedModel: null })
    }

    onSaveAsModel = async() => {
        const { type, models } = this.props
        const { item } = this.state.selectedConfig as any
        const model = await saveModel(item, true);

        if (!model) {
            return this.props.addError('Save Error', 'An error occurred saving portfolio as model, please try again.' )
        }
        this.props.addSuccessNotification({ message: 'Succesfully saved model' })

        const modelOption: SelectOption = { label: model.name, value: model.id, item: model }


        const update = [modelOption, ...models]

        this.props.updateModelsForType(type, update)

        this.setState({ selectedModel: modelOption, selectedConfig: null })
    }

    onShowUploadPortfolio = () => {
        this.setState({ showPortfolioUpload: true })
    }

    onCloseUploadPortfolio = () => {
        this.setState({ showPortfolioUpload: false, showLoadingPortfolio: false, selectedFile: undefined })
    }

    onUploadPortfolio = async() => {
        const { selectedFile } = this.state
        if (!selectedFile) {
            this.onCloseUploadPortfolio()
            return
        }
        this.setState({ showLoadingPortfolio: true })
        const portfolio = await this.loadSelectedFile()
        const hasInvalidTickers = portfolio.find((item) => !item.ticker || (!item.ticker.id || item.ticker.id === '0'))
        if (hasInvalidTickers) {
            this.props.addError('Upload Portfolio', 'Invalid tickers detected, please update items in red to a valid ticker')
        } else {
            this.props.addSuccessNotification({ title: 'Upload Portfolio', message: 'Portfolio uploaded! Please double-check the loaded tickers are the desired ones.'})
        }
        this.setState({ showLoadingPortfolio: false })
        
        this.props.updatePortfolioForType(this.props.type, [...portfolio, getNewTickerListItem()])
        this.onCloseUploadPortfolio()
    }

    loadSelectedFile = async() => {
        const { selectedFile } = this.state
        if (!selectedFile) {
            return []
        }
        const uploadedPortfolio = await csvFileToJson<TickerAllocationFlat>(selectedFile, { columns: ['ticker', 'weight'] })
        if (uploadedPortfolio.length > 100) {
            this.props.addError('Upload Portfolio', 'Uploaded portfolio exceeded 100 item limit')

            return []
        }

        return expandTickerAllocation(uploadedPortfolio)
    }

    onFileSelected = (selectedFile?: File) => {
        this.setState({ selectedFile })
    }

    onTickerProxyUpdated = async(_?: Ticker) => {
        const {
            type,
            portfolio 
        } = this.props
        this.props.updatePortfolioForType(type, portfolio);
    }

    onTickerProxyEnabledToggled = async(_: boolean) => {
        const {
            type,
            portfolio 
        } = this.props
        this.props.updatePortfolioForType(type, portfolio);
    }

    onAdvisoryFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value === '') {
            this.setState({ advisoryFee: null });
            return;
        }
        const periodCount = value.split('.').length - 1;
        if (periodCount > 1) {
            return;
        }
        if (value.endsWith('.')) {
            this.setState({ advisoryFee: value });
            return;
        }
        
        if (isNaN(Number(value))) {
            return;
        }
        this.setState({ advisoryFee: value });
    }

    onAdvisoryFeeChangeLeave = () => {
        const { advisoryFee } = this.state;
        let safeValue = advisoryFee;
        if (!advisoryFee || isNaN(Number(advisoryFee))) {
            safeValue = '0.0';
        }

        this.props.updateAdvisoryFeeForType(this.props.type, Number(safeValue));
    }

    onShowPortfolioHistory = async() => {
        this.setState({ loadingHistory: true })
        const { portfolio, portfolioHistory } = this.props
        const selectedItem = this.state.selectedConfig?.item as PortfolioDesignerConfiguration || this.state.selectedModel?.item as PortfolioDesignerConfiguration
        const { dates, items } = await flattenedPortfolioHistory({...selectedItem, portfolio, history: portfolioHistory} as PortfolioDesignerConfiguration)

        this.setState({ showPortfolioHistoryModal: true, loadingHistory: false, historyDates: dates, flattenedHistory: items })
    }

    onSavePortfolioHistory = async (dates: string[], data: PortfolioHistoryFlattened[]) => {
        const { current, history } = expandPortfolioHistory(dates, data)
        const selectedItem = this.state.selectedConfig?.item as PortfolioDesignerConfiguration || this.state.selectedModel?.item as PortfolioDesignerConfiguration
        const updated = { ...selectedItem, portfolio: current, history: history || [] } as PortfolioDesignerConfiguration
        this.setState({ showPortfolioHistoryModal: false })
        
        await this.onUpdateConfiguration(updated.id as number, updated)
    }

    onClosePortfolioHistory = () => {
        this.setState({ showPortfolioHistoryModal: false })
        const {
            type,
            portfolio 
        } = this.props
        this.props.updatePortfolioForType(type, portfolio);
    }

    render() {
        const {
            type,
            portfolio,
            configs,
            models,
            snapshots,
            snapshotLoading,
            snapshotTargetItem,
            snapshotTargetItemUpdateType,
            rebalancingFrequency,
            metrics,
        } = this.props
        const {     
            portfolioWeightTotal,
            showConfirmDeleteModal,
            showConfirmDeleteModelModal,
            showSaveAsModal,
            showPortfolioUpload,
            selectedConfig,
            selectedModel,
            selectedFile, } = this.state

        return (
                
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}>
                <ConfirmDeleteSetModal
                    visible={showConfirmDeleteModal}
                    item={selectedConfig?.item}
                    onConfirm={() => {
                        this.setState({ showConfirmDeleteModal: false, })
                        this.onDeleteConfirmed()
                    }}
                    onCancel={() => {
                        this.setState({ showConfirmDeleteModal: false })
                    }}
                />
                <ConfirmDeleteSetModal
                    visible={showConfirmDeleteModelModal}
                    item={selectedModel?.item}
                    onConfirm={() => {
                        this.setState({ showConfirmDeleteModelModal: false, })
                        this.onDeleteModelConfirmed()
                    }}
                    onCancel={() => {
                        this.setState({ showConfirmDeleteModelModal: false })
                    }}
                />
                <SaveAsModal
                    visible={showSaveAsModal}
                    name={selectedConfig ? selectedConfig.item.name : '' }
                    onSave={this.onSaveAsConfirmed}
                    onCancel={() => {
                        this.setState({ showSaveAsModal: false })
                    }}
                />
                <ConfirmModal
                    title={`${TYPE_LABEL[type]} Upload`}
                    confirmLabel={'Upload'}
                    visible={showPortfolioUpload}
                    onCancel={() => this.setState({ showPortfolioUpload: false })}
                    onConfirm={this.onUploadPortfolio}
                    confirmDisabled={!selectedFile}
                >
                    <FileUpload
                        sx={{
                            width: '100%',
                            height: '25rem',
                            paddingTop: '5rem',
                            paddingBottom: '5rem',
                        }}
                        ref={(view: FileUpload) => this.uploadRef = view}
                        showProgress={false}
                        onFileSelected={this.onFileSelected}
                        fileAccept={'.csv'}
                    />
                </ConfirmModal>
                    
                <Text sx={{ marginBottom: '1.5rem' }} type={'form_header'}>{TYPE_LABEL[type]}</Text>
                            
                <Box sx={{ display: 'flex', marginBottom: '1.5rem', alignItems: { xs: 'unset', xl: 'center' }, flexDirection: { xs: 'column', xl: 'row' } }}>
                    <Text sx={{ width: '15rem', minWidth: '15rem', marginRight: '1rem' }}>Saved<br />{TYPE_LABEL[type]}</Text>
                    <Select sx={{ width: '35rem', marginRight: '1rem' }} options={configs} value={selectedConfig} onChange={this.onConfigurationSelected} />

                    <Box sx={{ marginTop: { xs: '1rem', xl: 'unset' }, display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TooltipContainer
                                hoverContentsSx={{ margin: '1rem' }}
                                hoverContents={
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Text noGlossary>Upload a csv with tickers in first column and weights in second column, no header row.</Text>
                                    </Box>
                                }
                            >
                                <Button autoFit sx={{ height: '4.5rem' }} title={`Upload ${TYPE_LABEL[type]}`} onClick={this.onShowUploadPortfolio} />
                            </TooltipContainer>
                        </Box>
                        <Button autoFit sx={{ height: '4.5rem' }} title={'Save As'} onClick={this.onSave} />
                        {selectedConfig && <Button autoFit title={'Push to Models'} onClick={this.onSaveAsModel} /> }
                        {selectedConfig && <Button autoFit sx={{ height: '4.5rem' }} title={'Delete'} onClick={this.onDelete} />}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', marginBottom: '1.5rem', alignItems: 'center' }}>
                    <Text sx={{ width: '15rem', minWidth: '15rem', marginRight: '1rem' }}>Model {TYPE_LABEL[type]}</Text>
                    <Select sx={{ width: '35rem', marginRight: '1rem' }} options={models} value={selectedModel} onChange={this.onModelSelected} />
                    {selectedModel && !selectedModel.item.isSystem && <Button autoFit title={'Delete'} onClick={this.onDeleteModel} />}
                </Box>
                <Box sx={{ display: 'flex', marginBottom: '1.5rem', alignItems: 'center' }}>
                    <Text sx={{ width: '15rem', minWidth: '15rem', marginRight: '1rem' }}>Rebalancing Frequency</Text>
                    <Select sx={{ width: '35rem', marginRight: '1rem' }} options={REBALANCING_FREQUENCY} value={rebalancingFrequency} onChange={this.onBalancingFrequencyChanged} />
                    {(selectedConfig || selectedModel) && !(selectedConfig || selectedModel)?.item?.isSystem  && <Button autoFit title={'Rebalancing Settings'} onClick={this.onShowPortfolioHistory} loading={this.state.loadingHistory} inlineLoading disabled={this.state.loadingHistory} />}
                </Box>
                <PortfolioHistoryModal
                    visible={this.state.showPortfolioHistoryModal}
                    data={this.state.flattenedHistory}
                    dates={this.state.historyDates}
                    onCancel={this.onClosePortfolioHistory}
                    onSave={this.onSavePortfolioHistory}
                    onTickerProxyEnabledToggled={this.onTickerProxyEnabledToggled}
                    onTickerProxyUpdated={this.onTickerProxyUpdated}
                />
                <PortfolioList 
                    ref={view => this.portfolioList = view}
                    weightTotal={portfolioWeightTotal}
                    items={portfolio}
                    metrics={metrics}
                    snapshots={snapshots}
                    snapshotLoading={snapshotLoading}
                    snapshotTargetItem={snapshotTargetItem}
                    snapshotTargetItemUpdateType={snapshotTargetItemUpdateType}
                    onTickerProxyUpdated={this.onTickerProxyUpdated}
                    onTickerProxyEnabledToggled={this.onTickerProxyEnabledToggled}
                    onClearItems={this.onClearItems}
                    onAddItem={this.onAddToPortfolio}
                    onItemRemoved={this.onPortfolioItemRemoved}
                    onItemChange={this.onPortfolioItemChanged}
                />
                {/* <TextField 
                    sx={{ marginTop: '1.5rem', width: '100%', maxWidth: '20rem', marginBottom: '4rem' }}
                    label={<Typography sx={{ font: FONT_STYLES_BY_TYPE.text, paddingRight: -10 }}>Advisory Fee (%)</Typography>}
                    inputProps={{ sx: { font: FONT_STYLES_BY_TYPE.text } }}
                    variant={'outlined'}
                    defaultValue={0.0}
                    value={this.state.advisoryFee}
                    onChange={this.onAdvisoryFeeChange}
                    onBlur={this.onAdvisoryFeeChangeLeave}
                /> */}
            </Box>
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState, { type }: any) => {
    const portfolio = portfolioDesigner[`${type}Portfolio`]
    const portfolioHistory = portfolioDesigner[`${type}PortfolioHistory`]
    const configs = portfolioDesigner[`${type}Configs`]
    const models = portfolioDesigner[`${type}Models`]
    const rebalancingFrequency = portfolioDesigner[`${type}RebalancingFrequency`]
    const snapshots = portfolioDesigner[`${type}Snapshots`]
    const snapshotLoading = portfolioDesigner[`${type}SnapshotLoading`]
    const snapshotTargetItem = portfolioDesigner[`${type}SnapshotTargetItem`]
    const snapshotTargetItemUpdateType = portfolioDesigner[`${type}SnapshotTargetItemUpdateType`]
    const advisoryFee = portfolioDesigner[`${type}AdvisoryFee`]
    const {metrics} = portfolioDesigner

    return { portfolio, portfolioHistory, configs, models, rebalancingFrequency, snapshots, snapshotLoading, snapshotTargetItem, snapshotTargetItemUpdateType, metrics, advisoryFee }
}

export default connect(mapStateToProps, { addError, addSuccessNotification, updateRebalancingFrequencyForType, updateAdvisoryFeeForType, updateConfigsForType, updateModelsForType })(PortfolioDesigner)
