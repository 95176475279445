import { Box, SxProps } from '@mui/material';
import React, { FC } from 'react'
import Text from '../common/Text';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { DateLogs } from '../../actions/types/portfolioDesigner';

interface Props {
    logs?: DateLogs
    filter?: string[]
    sx?: SxProps
}

export const ResultDateLogs: FC<Props> = ({ logs, filter, sx }) => {
    if (!logs) {
        return null;
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem',
            ...(sx || {})
        }}>
            <Text>Data Notes:</Text>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {Object.keys(logs).filter(key => filter?.length ? filter.includes(key) : true ).map((key) => {
                    if (key === 'backtest_range') {
                        return null;
                    }
                    return (
                            <Box sx={{ display: 'flex', flexDirection: 'row', font: FONT_STYLES_BY_TYPE.text_small, marginRight: '3rem' }}>
                                {key}:
                                {Array.isArray(logs[key]) && (logs[key] as string[])?.map((item: string) => {
                                    const isHypotheticalPeriod = item.includes('proxied') || item.includes('backtest')
                                    return <Box sx={{ font: FONT_STYLES_BY_TYPE.text_small, fontWeight: isHypotheticalPeriod ? 'bold' : undefined, color: isHypotheticalPeriod ? 'red' : undefined, marginLeft: '.3rem', marginRight: '.3rem' }}>{item};</Box>
                                })}
                            </Box>
                        )
                })}
            </Box>
        </Box>  
    )
}