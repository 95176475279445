import React, { Component, ChangeEvent } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Box
} from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuid } from 'uuid'
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, PortfolioHistoryFlattened, Ticker } from '../../actions/types/portfolioDesigner';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import TickerInput from './TickerInput';
import { DatePicker } from '../common/styled/DatePicker';
import moment from 'moment';
import TickerProxyInput from './TickerProxyInput';
import Input from '../common/Input';

interface PortfolioHistoryTableProps {
  data: PortfolioHistoryFlattened[];
  dates: string[];
  onTableChange?: (dates: string[], data: PortfolioHistoryFlattened[]) => void;
  onTickerProxyUpdated?: (ticker?: Ticker ) => void
  onTickerProxyEnabledToggled?: (enabled: boolean) => void
}

interface PortfolioHistoryTableState extends PortfolioHistoryTableProps{
  newDateInput: string;
  selectedTicker?: Ticker | null;
}

/**
 * A table of tickers (rows) vs. date columns, with the ability to:
 * - Add/remove date columns
 * - Add/remove rows
 * - Drag rows to reorder them
 * - Edit the ticker name (string) and its weights (number)
 *
 * The dates are sorted from most recent to oldest, determined by comparing Date objects.
 */
export class PortfolioHistoryTable extends Component<PortfolioHistoryTableProps, PortfolioHistoryTableState> {
  constructor(props: PortfolioHistoryTableProps) {
    super(props);

    // Example initial state
    this.state = {
      data: props.data || [],
      dates: props.dates || [],
      newDateInput: '',
    };
  }

  componentDidUpdate(prevProps: PortfolioHistoryTableProps) {
    if (prevProps.data !== this.props.data || prevProps.dates !== this.props.dates) {
      this.setState({
        data: this.props.data,
        dates: this.props.dates
      });
    }
  }

  onNotifyTableChange = () => {
    if (this.props.onTableChange) {
      this.props.onTableChange(this.state.dates, this.state.data);
    }
  };

  /**
   * Sorts dates from most recent (largest) to oldest (smallest).
   */
  private sortDatesDesc(dates: string[]): string[] {
    return dates.sort((a, b) => {
      const dateA = new Date(a).getTime();
      const dateB = new Date(b).getTime();
      return dateB - dateA; // descending
    });
  }

  /**
   * Add a new date column if it doesn't already exist.
   */
  handleAddDate = () => {
    const { newDateInput, dates, data } = this.state;
    if (!newDateInput) return;

    const timeValue = moment(newDateInput)
    if (timeValue.isValid() === false) {
      alert('Invalid date format. Please try something like mm/dd/yyyy.');
      return;
    }
    const formatted = timeValue.format('YYYY-MM-DD');
    // If date already exists, do nothing (or handle differently)
    if (dates.includes(formatted)) {
      alert('That date already exists!');
      return;
    }

    // Insert new date into array
    const newDates = [...dates, formatted];
    const sortedDates = this.sortDatesDesc(newDates);

    // For each row in data, ensure weights has an entry (default 0)
    const updatedData = data.map((item) => {
      return {
        ...item,
        weights: {
          ...item.weights,
          [formatted]: 0
        }
      };
    });

    this.setState({
      dates: sortedDates,
      data: updatedData,
      newDateInput: '' // reset input
    }, this.onNotifyTableChange);
  };

  /**
   * Remove a date column.
   */
  handleRemoveDate = (dateToRemove: string) => {
    const { dates, data } = this.state;
    // Filter out the date from our date list
    const newDates = dates.filter((d) => d !== dateToRemove);

    // Also remove the key from each ticker's weights
    const updatedData = data.map((item) => {
      const { [dateToRemove]: _, ...otherDates } = item.weights;
      return {
        ...item,
        weights: otherDates
      };
    });

    this.setState({
      dates: newDates,
      data: updatedData
    }, this.onNotifyTableChange);
  };

  /**
   * Add a new row (ticker).
   */
  handleAddRow = (selectedTicker: Ticker) => {
    const { data, dates } = this.state;
    const existingTicker = data.find((item) => (item.ticker as Ticker)?.id === selectedTicker.id);
    if (existingTicker) {
      return;
    }
    const newItem: PortfolioHistoryFlattened = {
      id: uuid(),
      ticker: selectedTicker,
      weights: dates.reduce((acc, date) => {
        acc[date] = 0;
        return acc;
      }, {} as { [date: string]: number })
    };

    this.setState({
      data: [...data, newItem],
    }, this.onNotifyTableChange);
  };

  /**
   * Remove a row by ID or by index.
   */
  handleRemoveRow = (id: string) => {
    const { data } = this.state;
    const updated = data.filter((item) => item.id !== id);
    this.setState({ data: updated }, this.onNotifyTableChange);
  };

  /**
   * Handle updating ticker name or weight. We'll do it inline with onChange.
   */
  handleUpdateCell = (
    rowId: string,
    date: string | null,
    newValue: any
  ) => {
    const { data } = this.state;

    // If date === null, then we're editing the ticker cell
    if (date === null) {
      const updated = data.map((item) => {
        if (item.id === rowId) {
          return {
            ...item,
            ticker: newValue
          };
        }
        return item;
      });
      this.setState({ data: updated }, this.onNotifyTableChange);
    } else {
      // date is not null => we're editing the numeric weight
      let valueAsNumber = parseFloat(newValue);
      if (isNaN(valueAsNumber)) {
        valueAsNumber = 0;
      }
      const updated = data.map((item) => {
        if (item.id === rowId) {
          return {
            ...item,
            weights: {
              ...item.weights,
              [date]: valueAsNumber
            }
          };
        }
        return item;
      });
      this.setState({ data: updated }, this.onNotifyTableChange);
    }
  };

  /**
   * React DnD callback: reorder the rows in state when a drag is complete.
   */
  onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // If dropped outside the list or in the same position, do nothing
    if (!destination || destination.index === source.index) return;

    const { data } = this.state;
    const newData = Array.from(data);
    const [movedRow] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, movedRow);

    this.setState({ data: newData }, this.onNotifyTableChange);
  };

  onTickerSelected = (selectedTicker?: Ticker) => {
    if (!selectedTicker) return;
    this.handleAddRow(selectedTicker);
  };

  onDateUpdated = (newDate: string) => {
    this.setState({
      newDateInput: newDate,
    });
  };

  render() {
    const { data, dates, newDateInput } = this.state;
    const sortedDates = this.sortDatesDesc(dates);

    return (
      <Box sx={{ margin: '1rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {/* Controls to add a new row */}
        <Box sx={{ display: 'flex', gap: '0.5rem', marginBottom: '1rem' }}>
          <TickerInput
              containerSx={{ width: '30rem', border: (theme) => `1px solid ${theme.palette.primary.main}` }}
              placeholder={'Search for a ticker'}
              onPopperVisible={() => console.log('Popper visible')}
              onTickerSelected={this.onTickerSelected}
          />
        </Box>

          {/* Controls to add a new date column */}
          <Box sx={{ display: 'flex', gap: '0.5rem', marginBottom: '1rem' }}>
            <DatePicker
                sx={{ marginRight: '1rem'}}
                inputSx={{ height: undefined }}
                date={newDateInput ?? DEFAULT_MIN_DATE}
                format={'MM/dd/yyyy'}
                minDate={DEFAULT_MIN_DATE}
                maxDate={DEFAULT_MAX_DATE}
                onDateChanged={this.onDateUpdated} 
            />
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleAddDate}
              startIcon={<AddIcon />}
              sx={{
                background: `#6666CC 0% 0% no-repeat padding-box`,
                color: `#FFF`,
              }}
            >
              Add Date
            </Button>
          </Box>
        </Box>

        <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 400px)', overflowY: 'auto' }}>
          {/* We wrap our table with DnD */}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Table stickyHeader aria-label="portfolio-history-table" 
            sx={{
              ' & th, & td, & input': {
                font: FONT_STYLES_BY_TYPE.text,
              }
            }}>
              <TableHead>
                <TableRow>
                  {/* Column heading for Ticker */}
                  <TableCell sx={{ fontWeight: 'bold', minWidth: 150 }}>
                    Ticker
                  </TableCell>
                  {/* Render a TableCell for each distinct date */}
                  {sortedDates.map((date, i) => {
                    const dateFormatted = date.toLowerCase().includes('current') ? 'Current' : moment(date).format('MM/DD/YYYY');
                    const isLastDate = i === sortedDates.length - 1;
                    const nextDate = isLastDate ? 'Inception' : sortedDates[i + 1];
                    const nextDateFormatted = nextDate.toLowerCase().includes('inception') ? nextDate : moment(nextDate).add(1, 'day').format('MM/DD/YYYY');

                    return (
                      <TableCell
                        key={date}
                        sx={{ fontWeight: 'bold', minWidth: 120 }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box sx={{ flex: 1, textAlign: 'center' }}>{dateFormatted} - {nextDateFormatted}</Box>
                          <IconButton
                            size="small"
                            onClick={() => this.handleRemoveDate(date)}
                            aria-label="delete-date"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    )
                  })}
                  {/* An extra empty header cell for row-level delete */}
                  <TableCell />
                </TableRow>
              </TableHead>

              <Droppable droppableId="droppable-rows">
                {(provided) => (
                  <TableBody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {data.map((item, index) => (
                      <Draggable
                        key={item.id || index.toString()}
                        draggableId={item.id || index.toString()}
                        index={index}
                      >
                        {(providedRow) => (
                          <TableRow
                            hover
                            tabIndex={-1}
                            ref={providedRow.innerRef}
                            {...providedRow.draggableProps}
                            {...providedRow.dragHandleProps}
                          >
                            {/* First column: ticker name (editable) */}
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: 500, minWidth: 150 }}
                            >
                            <TickerInput
                                containerSx={{ width: '30rem', border: (theme) => `1px solid ${theme.palette.primary.main}` }}
                                placeholder={'Search for a ticker'}
                                onPopperVisible={() => console.log('Popper visible')}
                                value={typeof item.ticker === 'string' ? undefined : item.ticker as Ticker}
                                onTickerSelected={(selectedTicker) =>
                                  this.handleUpdateCell(
                                    item.id!,
                                    null,
                                    selectedTicker
                                  )
                                }
                            />
                            {item.ticker && 
                                <TickerProxyInput
                                    ticker={item.ticker as Ticker}
                                    onTickerProxyUpdated={this.props.onTickerProxyUpdated}
                                    onEnableToggled={this.props.onTickerProxyEnabledToggled}
                                />
                            }
                            </TableCell>

                            {/* Render each date as a cell, defaulting to 0 if missing */}
                            {sortedDates.map((date) => {
                              const weight = item.weights[date] ?? 0;
                              return (
                                <TableCell
                                  key={`${item.id}-${date}`}
                                  align="right"
                                >
                                <Input
                                    sx={{ width: '100%', font: FONT_STYLES_BY_TYPE.text_tiny, textAlign: 'center' }}
                                    accuracy={3}
                                    step={.1}
                                    value={weight}
                                    placeholder={'0.00'}
                                    decimalPlaces={2}
                                    onlyNotifyOnBlur
                                    useTextField
                                    textFieldProps={{ variant: 'standard' }}
                                    onChange={(value) => this.handleUpdateCell(
                                      item.id!,
                                      date,
                                      value
                                    )}
                                />
                                </TableCell>
                              );
                            })}

                            {/* Delete row button */}
                            <TableCell align="center">
                              <IconButton
                                color="error"
                                onClick={() => this.handleRemoveRow(item.id!)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </TableContainer>
      </Box>
    );
  }
}
