import { Component } from 'react'
import { connect } from 'react-redux';
import { Box } from '@mui/material'
import ConfirmModal from '../common/ConfirmModal';
import { ReduxState } from '../../reducers';
import { ReportSet, PortfolioHistory, ReportItem, SavedReport, PortfolioHistoryFlattened, Ticker } from '../../actions/types/portfolioDesigner';
import { ReportSetComponent } from './ReportSetComponent';
import Text from '../common/Text';
import { Select } from '../common/styled/Select';
import Button from '../common/Button';
import SaveAsModal from '../CustomCMA/SaveAsModal';
import { SelectOption } from '../../common/types';
import { deleteSavedReports, loadSavedReports } from '../../actions/portfolioDesigner';
import { createSavedReports, updateSavedReports } from '../../actions/portfolioDesigner';
import ConfirmDelete from '../common/ConfirmDelete';
import { TextFieldItem } from '../common/FormField';
import { PortfolioHistoryTable } from './PortfolioHistory';


interface ComponentProps {
    onSave: (date: string[], data: PortfolioHistoryFlattened[]) => void
    onCancel: () => void
    onTickerProxyUpdated?: (ticker?: Ticker ) => void
    onTickerProxyEnabledToggled?: (enabled: boolean) => void
    visible: boolean
    data: PortfolioHistoryFlattened[];
    dates: string[];
}

interface ReduxStateProps { }

interface ReduxActions { }

interface PortfolioHistoryModalModalState {
    data: PortfolioHistoryFlattened[];
    dates: string[];
}

type PortfolioHistoryModalModalProps = ComponentProps & ReduxStateProps & ReduxActions;

class PortfolioHistoryModal extends Component<PortfolioHistoryModalModalProps, PortfolioHistoryModalModalState> {

    constructor(props: PortfolioHistoryModalModalProps) {
        super(props);
        this.state = {
            data: props.data,
            dates: props.dates
        }
    }

    componentDidUpdate(prevProps: PortfolioHistoryModalModalProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data })
        }
        if (prevProps.dates !== this.props.dates) {
            this.setState({ dates: this.props.dates })
        }
    }

    

    onSave = () => {
        this.props.onSave(this.state.dates, this.state.data)
    }

    onTableChange = (dates: string[], data: PortfolioHistoryFlattened[]) => {
        this.setState({ dates, data })
    }

    render() {
        const { data, dates } = this.state
        const { visible } = this.props
        
        return (
            <ConfirmModal
                visible={visible}
                title={`Portfolio Designer History`}
                confirmLabel={'Save'}
                cancelLabel={'Cancel'}
                onConfirm={this.onSave}
                onCancel={this.props.onCancel}>
                    <PortfolioHistoryTable data={data} dates={dates} onTableChange={this.onTableChange} onTickerProxyEnabledToggled={this.props.onTickerProxyEnabledToggled} onTickerProxyUpdated={this.props.onTickerProxyUpdated} />
            </ConfirmModal>
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState) => ({ 
    savedReports: portfolioDesigner.savedReports
})

export default connect(mapStateToProps, { loadSavedReports, createSavedReports, updateSavedReports, deleteSavedReports })(PortfolioHistoryModal)